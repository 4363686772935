<template>
  <div>
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="姓名或手机号：">
              <el-input
                clearable
                v-model="searchForm.userName"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="课程名称：">
              <el-select
                style="width: 100%"
                filterable
                clearable
                :loading="false"
                v-model="searchForm.courseId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="学员状态：">
              <el-select
                clearable
                v-model="searchForm.status"
                placeholder="学员状态"
                class="w100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-form-item>
            <el-button type="primary" @click="searchList">查询</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center">
        <span>学员课程数据</span>
        <div class="flex_1"></div>
        <el-button style="float: right" type="primary" @click="openSelectModal"
          >学员报名</el-button
        >
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          :data="tableData"
          height="calc(100vh - 270px)"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '上课进度'">
                <span>
                  {{ scope.row.learnedCourseUnit || 0 }}/{{
                    scope.row.totalCourseUnit
                  }}
                </span>
              </template>
<!--              <template v-if="item.label === '课消进度'">-->
<!--                <span>-->
<!--                  {{ scope.row.usedAmount }}/{{ scope.row.totalAmount }}-->
<!--                </span>-->
<!--              </template>-->
              <template v-else-if="item.label === '所在班级'">
                <span v-if="scope.row.className">
                  {{ scope.row.className }}
                </span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '状态'">
                <el-tag :type="statusOptions[scope.row.status]">
                  {{ scope.row.status }}
                </el-tag>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button
                  type="text"
                  style="margin-right: 10px"
                  @click="openDetailModal(scope.row)"
                  >明细</el-button
                >
                <el-dropdown>
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-popconfirm
                        @onConfirm="confirmStatus(scope.row)"
                        @confirm="confirmStatus(scope.row)"
                        :title="`确定要为该学员退课吗？`"
                      >
                        <template v-if="scope.row.status == '在读'">
                          <el-button type="text" slot="reference">
                            退课
                          </el-button>
                        </template>
                      </el-popconfirm>
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="divideList.indexOf(scope.row.status) > -1"
                    >
                      <el-button
                        type="text"
                        @click="divideClassHandler(scope.row)"
                      >
                        分班
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.status == '在读'">
                      <el-button
                        type="text"
                        @click="adjustClassHandler(scope.row)"
                      >
                        调班
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <!-- 选择学员 -->
    <SelectStudentModal
      ref="select"
      title="选择学员"
      @openAddModal="openAddModal"
    />
    <!-- 添加学员 -->
    <AddStudentModal ref="add" title="添加" @submit="addMember" />
    <!-- 编辑 暂停 -->
    <EditModal ref="edit" title="编辑" :item="item" @submit="editMember" />
    <!-- 学员课消明细 -->
    <!-- TODO -->
    <StudentCourseDetailModal
      :user_id="user_id"
      :courseId="courseId"
      :searchForm="searchForms"
      ref="detail"
      title="明细"
    />
    <!-- 调班 -->
    <AdjustClass
      :studentInfo="studentInfo"
      :show="adjustDialog"
      @close="adjustDialog = false"
      :user_id="user_id"
      :classId="classId"
      @updateView="getListHandler"
    />
    <!-- 分班 -->
    <DividesClass
      :show="divideDialog"
      @close="divideDialog = false"
      :user_id="user_id"
      :courseId="courseId"
      @updateView="getListHandler"
    />
  </div>
</template>
<script>
import { addStudent, editStudent } from "@/api/member/student";
import {
  getListCourseOnly as getCourseList,
  getCourseMemberList,
  leaveCourse,
} from "@/api/businesscourse/course";
import AddStudentModal from "@/components/add-student/AddModal";
import EditModal from "@/views/member/EditModal";
import StudentCourseDetailModal from "./components/course-spend-detail";
import SelectStudentModal from "./components/SelectStudentModal";
import TextButton from "@/components/TextButton";
import AdjustClass from "@/components/merchantClass/adjust-class";
import DividesClass from "@/components/merchantClass/divides-class";

// 默认展示列
const baseColSetting = [
  {
    label: "姓名",
    prop: "userName",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "手机号",
    prop: "loginName",
    state: true,
    align: "center",
    width: "120",
  },
  {
    label: "课程名称",
    prop: "courseName",
    state: true,
    align: "center",
    width: "140",
  },
  {
    label: "上课进度",
    prop: "courseFinish",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "报名日期",
    prop: "createTime",
    state: true,
    sortable: true,
    align: "center",
    width: "180",
  },
  {
    label: "所在班级",
    prop: "className",
    state: true,
    align: "center",
    width: "150",
  },
  // {
  //   label: "剩余课时",
  //   prop: "amount",
  //   state: true,
  //   align: "center",
  //   width: "100",
  // },
  // {
  //   label: "课消进度",
  //   prop: "usedAmount",
  //   state: true,
  //   align: "center",
  // },
  {
    label: "状态",
    prop: "status",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: {
    AddStudentModal,
    EditModal,
    StudentCourseDetailModal,
    SelectStudentModal,
    TextButton,
    AdjustClass,
    DividesClass,
  },
  data() {
    return {
      studentInfo:null,
      adjustDialog: false,
      divideDialog: false,
      user_id: "",
      classId: "",
      courseId: "",
      seleStuData: [],
      courseOptions: [],
      size: "default",
      tableKey: 1,
      item: {},
      title: "",
      tableData: [],
      detailTableData: [],
      detailPageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: { userName: null, courseName: null, status: null },
      searchForms: {},
      statusOptions: {
        //'待分班','在读','结课','退课'
        待分班: "info",
        在读: "success",
        结课: "warning",
        退课: "danger",
      },
      divideList: ["待分班", "结课", "退课"],
      options: [
        {
          value: "待分班",
          label: "待分班",
        },
        {
          value: "在读",
          label: "在读",
        },
        {
          value: "结课",
          label: "结课",
        },
        {
          value: "退课",
          label: "退课",
        },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getListHandler();
    this.getCourseList();
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    /** 获取课程 */
    getCourseList() {
      getCourseList({
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        this.courseOptions = res.body.list.map((i) => ({
          value: i.id,
          label: i.title,
        }));
      });
    },
    /**
     * 分班弹窗
     */
    divideClassHandler(row) {
      this.user_id = row.userId;
      this.courseId = row.courseId;
      this.$nextTick(() => {
        this.divideDialog = true;
      });
    },
    /**
     * 调班弹窗
     */
    adjustClassHandler(row) {
      this.studentInfo = row
      this.user_id = row.userId;
      this.classId = row.classId;
      this.$nextTick(() => {
        this.adjustDialog = true;
      });
    },
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getCourseMemberList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { userId, courseId } = item;
      leaveCourse({
        userId,
        courseId,
      }).then((res) => {
        if (res.state == "success") {
          this.$message.success("退课成功");
          this.getListHandler();
        }
      });
    },
    searchList(){
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        userName: null,
        courseName: null,
        status: null,
      };
      this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    /**
     * 打开报名
     */
    openSelectModal() {
      this.$refs.select.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.select.initData();
      });
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      // this.$router.push({ name: "Spend", params: item });
      this.user_id = item.userId;
      this.courseId = item.courseId;
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name, tel, pwd, loginName,sex } = value;
      const response = await addStudent({
        sex,
        name,
        tel,
        pwd,
        loginName,
        state: "1",
        currentState: "试听",
      });
      if (response.state == "success") {
        // this.pageInfo.pageNum = 1;
        // this.pageInfo.pageSize = 10;
        this.$message.success("添加成功");
        this.getListHandler();
        this.$refs.add.dialogVisible = false;
      }
    },
    async editMember(value) {
      const { id, name, tel, pwd, state } = value;
      const response = await editStudent({
        id,
        name,
        tel,
        pwd,
        state,
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        //   this.pageInfo.pageNum = 1;
        //   this.pageInfo.pageSize = 10;
        this.getListHandler();
        this.$refs.edit.dialogVisible = false;
      } else {
        this.$message.warning("编辑失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/container.scss";

.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}

.start-class,
.end-class,
.read-class,
.quit-class {
  display: inline-block;
  position: relative;
}
.start-class::before,
.end-class::before,
.read-class::before,
.quit-class::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.start-class::before {
  background-color: gray;
}
.read-class::before {
  background-color: blue;
}
.quit-class::before {
  background-color: red;
}
.end-class::before {
  background-color: green;
}
/deep/.el-card__body {
  padding-bottom: 0px;
}
/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
